.get-started-title h3 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 37px;
	text-align: center;
	color: #131521;
	padding-bottom: 24px;
}

.get-started-title p {
	max-width: 650px;
	font-style: normal;
	font-weight: 450;
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	color: #4C506B;
	font-family: "Circular Std Book";
	margin: 0 auto;
	padding-bottom: 48px;
}

.gist-conatiner {
	max-width: 1110px;
	margin: 0 auto;
}

.gist-section .gist-section-wrapper {
	display: flex;
	background: #ffffff;
	box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.06);
	border-radius: 10px;
}

.gist-left-contents,
.gist-right-contents {
	flex-basis: 50%;
	width: 50%;
}

.gist-left-contents {
	background: #ffffff;
	padding: 40px 48px;
	border-radius: 10px 0 0 10px;
}

.gist-right-contents {
	border-radius: 0 10px 10px 0;
	background: #f2f3f9;
}

.gist-slider-wrapper {
	padding-bottom: 40px;
}

.gist-slider-text {
	padding-bottom: 20px;
}

.gist-slider-text h4,
.gist-slider-texts h4 {
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 26px;
	color: #131521;
	font-family: "CircularStd";
	padding-bottom: 4px;
}

.gist-slider-text p,
.gist-slider-texts p {
	font-style: normal;
	font-weight: 450;
	font-size: 14px;
	line-height: 22px;
	color: #4c506b;
	font-family: "Circular Std Book";
}

/* .gist-slider {
	position: relative;
	padding: 4px;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.07);
	border-radius: 48px;
	height: 24px;
} */

/* .gist-slider .input-range {
	height: auto;
} */

/* .gist-slider .input-range__track--background {
	margin-top: -1px !important;
} */

/* .gist-slider .input-range::after {
	content: "\002B";
	position: absolute;
	top: -6px;
	right: 6px;
	font-size: 18px;
	color: #fff;
	z-index: 5;
} */

/* .gist-slider .input-range::before {
	content: "\2212";
	position: absolute;
	top: -6px;
	left: 6px;
	font-size: 18px;
	color: #fff;
	line-height: none;
	z-index: 5;
}*/

.gist-section-wrapper .input-range__label {
	display: none;
}

.gist-section-wrapper .input-range__track--active {
	background: linear-gradient(270.02deg,
			#7195fc 30.31%,
			#3864e4 256.26%) !important;
}

/* .gist-section-wrapper .input-range__track--active {
	background: linear-gradient(270.02deg,
			#7195fc 14.53%,
			#3864e4 292.68%) !important;
	border-radius: 50px;
}

.gist-section-wrapper .input-range__track {
	height: 16px !important;
	background: linear-gradient(270.02deg,
			#7195fc 14.53%,
			#3864e4 292.68%) !important;
	border-radius: 50px;
} */

/* .gist-section-wrapper .input-range__slider {
	border-radius: 50% !important;
	background-color: rgb(255, 255, 255) !important;
	box-shadow: inherit !important;
	border: 1px solid #ffffff !important;
	width: 12px !important;
	height: 12px !important;
	margin-top: -14px !important;
	margin-left: 0px !important;
	margin-right: 0 !important;
} */

.gist-right-contents .homevalue-annualfee {
	display: flex;
	justify-content: center;
	gap: 5px;
	background: #f6f7fd;
	padding: 40px 48px;
}

.gist-homevalue h6,
.gist-annualfee h6 {
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	color: #4c506a;
	font-family: "CircularStd";
	padding-bottom: 15px;
}

.gist-homevalue h6 {
	position: relative;
	left: -17px;
}

/* .gist-annualfee h6 {
	position: relative;
	right: -40px;
} */

.homevalue-annualfee .arrow {
	margin-top: 60px;
}

.gist-right-contents .homevalue-annualfee-lists {
	background: #f2f3f9;
	padding: 32px 48px;
	border-top: 1px solid #e9ebf2;
}

.homevalue-annualfee-lists ul li {
	list-style: none;
	font-style: normal;
	font-weight: 450;
	font-size: 16px;
	line-height: 24px;
	color: #4c506b;
	font-family: "Circular Std Book";
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	gap: 12px;
}

.homevalue-annualfee-lists ul li img {
	width: 26px;
}

.mobile-gist-slider-texts {
	display: none;
}

@media only screen and (max-width: 1200px) {
	.gist-conatiner {
		padding: 0 30px;
	}
}

@media only screen and (max-width: 840px) {

	.gist-left-contents,
	.gist-right-contents .homevalue-annualfee-lists,
	.gist-right-contents .homevalue-annualfee {
		padding: 32px;
	}
}

@media only screen and (max-width: 704px) {
	.gist-section .gist-section-wrapper {
		flex-direction: column;
	}

	.gist-left-contents,
	.gist-right-contents .homevalue-annualfee {
		padding: 40px;
	}

	.gist-right-contents .homevalue-annualfee-lists {
		padding: 32px 40px;
	}

	.web-gist-slider-texts {
		display: none;
	}

	.mobile-gist-slider-texts {
		display: block;
		padding-top: 40px;
	}

	.gist-left-contents,
	.gist-right-contents {
		flex-basis: 100%;
		width: 100%;
	}

	.gist-section-wrapper {
		overflow: hidden;
	}

	.gist-homevalue,
	.gist-annualfee {
		width: 100%;
	}

	.gist-annualfee-icon,
	.gist-homevalue-icon {
		min-width: 0px !important;
	}

}

@media only screen and (max-width: 580px) {
	.gist-conatiner {
		padding: 0 15px;
	}

	.gist-left-contents,
	.gist-right-contents .homevalue-annualfee {
		padding: 32px 18px;
	}

	.gist-right-contents .homevalue-annualfee-lists {
		padding: 32px 18px;
	}
}

@media only screen and (max-device-width: 480px) {
	.gist-conatiner {
		padding: 0 15px;
	}

	.gist-left-contents,
	.gist-right-contents .homevalue-annualfee {
		padding: 32px 18px;
		/* display: block; */
	}

	.gist-right-contents .homevalue-annualfee-lists {
		padding: 32px 18px;
	}
	
}
