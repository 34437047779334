.rent-table {
	border: 1px solid rgba(0, 0, 0, 0.1);
	text-align: left;
	background: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 5%);
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 0px;
}



.rental-table {
	border: 1px solid rgba(0, 0, 0, 0.07);
	border-radius: 10px;
}

.rent-table th {
	background: #faf9f8;
	border: 1px dashed #e6e6e6;
	border-bottom: 1px solid #dee2e6;
}

.rent-table thead th {
	border-bottom-width: 1px;
	font-size: 13px;
	line-height: 16px;
	color: #5b607d;
	font-family: "CircularStd";
	color: #5b607d;
	font-weight: bold;
	padding: 15px 30px;
	background: rgba(56, 99, 228, 0.05);
}

.rent-table td {
	border: 1px dashed #e6e6e6;
	/* background: white; */
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	color: #31354e;
	font-family: "Circular Std Book";
	padding: 20px 30px;
}


.rent-table td img {
	display: flex;
	width: 24px;
	margin: 0 auto;
	align-items: center;
}

.loan-table th:nth-child(2) {
	width: 115px;
	text-align: center;
}


.rent-table th:nth-child(3) {
	width: 170px;
	text-align: center;
}


.rent-table tbody tr:nth-child(even) {
	background: rgba(245, 247, 254, 0.4);
}

@media only screen and (max-width: 580px) {


	.rent-table thead th {
		padding: 15px;
	}


	.rent-table th:nth-child(2) {
		width: inherit;
	}


	.rent-table th:nth-child(3) {
		width: inherit;
	}


	.rent-table td {
		padding: 20px 15px;
		font-size: 15px;
		line-height: 20px;
	}
}