.region-sec,
.region-sec-2 {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 30px;
}

.region-sub-title,
.region-sub-title-2,
.region-sub-title-3 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #131521;
  font-family: "CircularStd";
  margin-bottom: 16px;
}

.region-sub-title-1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  color: #131521;
  margin-bottom: 32px;
}

.region-sub-title {
  margin-bottom: 16px;
  margin-top: 32px;
}

.region-table-wrapper .table thead th {
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 16px 24px;
  color: #5b607d;
  position: relative;
  z-index: 10;
  width: 22%;
  min-width: 100%;
  font-family: "CircularStd";
}

.region-table-wrapper .table thead th {
  width: 4%;
}

.region-table {
  text-align: left;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: block !important;
}

.region-table thead th {
  border-bottom: 1px solid #e6e6e6;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #5b607d;
  font-family: "CircularStd";
  background: rgba(56, 99, 228, 0.05);
  font-weight: bold;
  padding: 16px;
  min-height: 64px;
  text-align: left;
  vertical-align: middle;
  border-right: 1px solid #e6e6e6;
}

.region-table td {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #31354e;
  height: 60px;
  vertical-align: middle;
  text-align: left;
  padding: 12px 24px;
  font-family: "Circular Std Book";
}

.region-table thead th:last-child {
  border-right: 0;
}

.region-table tbody tr {
  color: #fff;
  border-bottom: 1px dashed #e6e6e6;
}

.region-table tbody tr:last-child {
  border-bottom: 0;
}

.region-table tbody tr:nth-child(even) {
  background: rgba(245, 247, 254, 0.4);
}

.region-table tbody tr:hover {
  background-color: #f7f8ff;
  cursor: pointer;
}

.region-status-tag {
  background: rgba(83, 182, 73, 0.12) !important;
  backdrop-filter: blur(10px) !important;
  border-radius: 60px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #53b649 !important;
  height: 23px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.region-sold-status-tag {
  background: rgba(182, 73, 73, 0.12) !important;
  backdrop-filter: blur(10px) !important;
  border-radius: 60px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #b64949 !important;
  height: 23px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.region-graph .quity-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #131521;
}

.region-breadcrumb {
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 48px;
}

.region-breadcrumb .breadcrumb-item,
.region-breadcrumb .breadcrumb-item a,
.region-breadcrumb .breadcrumb-item.active {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #3864e4;
  font-family: "CircularStd";
  padding: 0 7px;
}

.region-breadcrumb .breadcrumb-item a {
  padding: 0;
}

.region-breadcrumb .breadcrumb-item.active {
  color: #131521;
}

.fa-chevron-right:before,
.breadcrumb-item .fa-chevron-right,
.breadcrumb-item .fa {
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 14px !important;
  color: #a7a9b2 !important;
  margin-top: 0 !important;
}

.region-chart {
  border-bottom: 1px solid #ededed;
  padding-bottom: 64px;
  max-width: 1110px;
  margin: 0 auto;
  margin-bottom: 64px;
}

.region-sec .region-paragraph,
.region-sec p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #131521;
  font-family: "Circular Std Book";
}

.region-sec .region-paragraph {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #ededed;
}

.region-sec .region-link {
  color: #3864e4;
  transition: color 0.3s ease-in-out;
}

.region-sec .region-link:hover,
.region-breadcrumb .breadcrumb-item a:hover {
  color: #0e36af;
}

.region-sec .region-title {
  margin-bottom: 16px;
}

.region-sec .region-sub-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5b607d;
  font-family: "Circular Std Book";
  padding-bottom: 12px;
}

.region-pagination {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.region-pagination .btn-primary {
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  color: #a7a9b2;
  min-height: 40px;
  font-family: "CircularStd";
}

.region-pagination .btn-primary:hover {
  color: #0e36af;
  border: 1px solid #0e36af;
}

.region-pagination .btn-primary:active {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.03) !important;
  color: #0e36af !important;
  background-color: #ffffff !important;
  border: 1px solid #0e36af !important;
  outline: none;
  border: none;
}

.region-pagination .btn-primary:disabled:hover {
  cursor: not-allowed;
  border: 1px solid #ededed;
  color: #a7a9b2;
}

.region-pagination .pagination-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  color: #a7a9b2;
  font-family: "Circular Std Book";
}

.articlecontent {
  padding: 10px 0;
}
.articlecontent p {
  margin-bottom: 15px;
}

@media only screen and (max-width: 580px) {
  .region-sec {
    padding: 0 15px;
    margin-top: 110px !important;
  }

  .region-sec h1 {
    margin-bottom: 24px;
  }

  .region-sec-2 {
    padding: 0 15px;
  }

  .region-breadcrumb {
    padding: 12px;
  }
}
