.picker-container {
  max-width: 430px;
  user-select: none;
}

.picker-container .picker-column .picker-item {
  color: #9fa2b8;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  padding: 0 !important;
  text-align: left !important;
}

.picker-container .picker-column .picker-item:hover {
  cursor: default;
}

.picker-container .picker-inner {
  padding: 0px !important;
  width: 430px;
}

.picker-container .picker-column .picker-item.picker-item-selected {
  color: #131521;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.hero_scroll_listitems {
  padding-top: 35px;
  margin-left: 15px;
}

.hero_scroll_listitems li {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4c506b;
  font-family: "Circular Std Book";
  margin-bottom: 10px;
}

.hero_scroll_listitems li:last-child {
  list-style: none;
  position: relative;
}

.hero_scroll_listitems li:last-child::after {
  content: "\002A";
  position: absolute;
  font-size: 19px;
  left: -14px;
  top: 4px;
}

.picker-inner .picker-column:nth-child(3) .picker-item,
.picker-inner .picker-column:nth-child(5) .picker-item {
  font-size: 14px;
  font-weight: 400;
  width: 100px;
}

.picker-container .picker-inner div.picker-item{
  cursor: pointer !important;
}

@media only screen and (max-width: 580px) {
  .picker-container .picker-column .picker-item,
  .picker-container .picker-column .picker-item.picker-item-selected {
    font-size: 13px;
  }

  .picker-container .picker-inner {
    width: 290px;
  }
}
