.accerdition-content h5 {
  font-weight: bold;
}
.status {
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}

.not_verified {
  height: 20px !important;
  width: 20px !important;
  border-radius: 50px !important;
  background-color: red !important;
  margin-right: 7px;
}
.verified {
  height: 20px !important;
  width: 20px !important;
  border-radius: 50px !important;
  background-color: green !important;
  margin-right: 7px;
}

.main-upload{
  border: 3px solid #000;
  border-style: dashed;
  height: 200px;
  width: 400px;
  border-radius: 15px;
}

.border-tbl{
  border:3px solid black;
  padding: 5px;
}