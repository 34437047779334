.featured_container_wrapper {
	max-width: 1140px;
	margin: 0 auto;
}

.featured_region_title {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 37px;
	text-align: center;
	color: #131521;
	margin-bottom: 40px;
}

.featured_regions_card {
	height: 263px;
	margin: 0 15px;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.07);
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	padding: 28px 32px 32px;
	transition: all 0.3s;
	cursor: pointer;
}

.featured_regions_card:hover{
	background-color: #ebeffc;
}

.slider_badge {
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 14px;
	text-align: center;
	color: #131521;
	padding: 4px 12px;
	font-family: "CircularStd";
	background: rgba(19, 21, 33, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 60px;
}

.featured_card_title {
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 28px;
	color: #131521;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-family: "CircularStd";
	margin-top: 20px;
	margin-bottom: 12px;
}

.featured_regions_text {
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-weight: 40;
	font-size: 16px;
	line-height: 24px;
	color: #4c506b;
	font-family: "Circular Std Book";
}


.slick_arrows {
	position: absolute;
	z-index: 2;
	font-size: 0;
	line-height: 0;
	top: 50%;
	width: 40px;
	height: 40px;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: 0;
	padding-top: 10px;
	text-align: center;
	cursor: pointer;
	background: rgba(236, 237, 243, 0.55);
	color: #4c506b;
	border-radius: 50%;
}

.slick_prev_arrow {
	left: -50px;
}

.slick_next_arrow {
	right: -50px;
}

.slick_next_arrow::after {
	content: "";
	position: absolute;
	background-image: url("../../Images/featured-arrow-next.svg");
	background-repeat: no-repeat;
	top: 13px;
	left: 17px;
	width: 8px;
	height: 14px;
}

.slick_prev_arrow::before {
	content: "";
	position: absolute;
	background-image: url("../../Images/featured-arrow-prev.svg");
	background-repeat: no-repeat;
	top: 13px;
	left: 15px;
	width: 8px;
	height: 14px;
}

.slick_prev_arrow svg,
.slick_next_arrow svg {
	display: none;
}

.slick_prev_arrow .slick_list .slick_track{
	height: 280px;
}


.browse_all_btn {
	font-style: normal;
	text-align: center;
	font-size: 16px;
	padding: 11px 24px;
	background: rgba(236, 237, 243, 0.55);
	border-radius: 60px;
	font-family: "CircularStd";
	font-weight: 500;
	color: #3864e4;
	margin-top: 40px;
	transition: all 0.3s ease-in-out;
}


.feaured_badge_link {
    color: #3864e4;
}

.feaured_badge_link:hover,
.browse_all_btn:hover {
	cursor: pointer;
	color: #fff;
	background: #3864e4;
}

.browse_all_btn:hover a {
	color: #fff;
}

@media only screen and (max-width: 1200px) {
	.slick_prev_arrow {
		left: 0px;
	}

	.slick_next_arrow {
		right: 0;
	}
}

@media only screen and (max-width: 600px) {
	.region_card_container {
		max-width: 100%;
		width: 100%;
	}

	.region_card_container .container {
		margin-left: 0;
		margin-right: 30px;
		padding-right: 30px;
		max-width: 100%;
		width: 100%;
	}
}
