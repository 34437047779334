.box-top-text {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 38px;
	text-align: center;
	color: #131521;
	font-family: "Circular Std Book";
}
.addressfilter {
}
.addressfilter__control {
	border: none !important;
	box-shadow: 0 0 0 0 !important;
}
.addressfilter__value-container {
	height: 74px;
	cursor: text !important;
}

.addressfilter__control--is-focused {
	display: block !important;
}

.addressfilter__control--menu-is-open {
	display: block !important;
}

.addressfilter__menu {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 50px;
	letter-spacing: 0.2px;
	color: #373f41;
	font-family: "Circular Std Book";
	background: #ffffff !important;
	box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.08) !important;
	border-radius: 10px !important;
	padding: 8px 0;
	margin-bottom: 100px !important;
}

.addressfilter__menu div {
	border-radius: 6px;
	padding: 0 8px;
	margin: 0;
	z-index: 11111111111111111111 !important;
	position: relative !important;
}

.property-model-header {
	display: grid;
	grid-template-columns: 1fr 60px;
}

.property-model .modal-header {
	width: 55px;
}

/* .css-1okebmr-indicatorSeparator{
    display: none !important;
}

.css-tlfecz-indicatorContainer {
    display: none !important;
}
.css-1wy0on6 {
    display: none !important;
} */
.search-input .css-yk16xz-control,
.search-input .css-1hwfws3 {
	cursor: text !important;
}

#react-select-2-input {
	opacity: 1 !important;
}

.modal {
	z-index: 1111111111 !important;
}

.modal-dialog {
	max-width: 880px !important;
	width: 100% !important;
	padding: 20px 30px !important;
	border-radius: 20px !important;
	margin: 0 auto !important;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
}

.modal-wrapper {
	top: 0;
	left: 0;
	transform: translate(0, 0);
}

.modal-header {
	padding: 0 20px !important;
	border: 0;
	height: 30px;
	background: transparent;
}

.modal-header .close {
	padding: 1px 6px !important;
	margin: -30px -22px -1rem auto !important;
	border: none;
	border-radius: 100px;
	background: rgba(74, 78, 102, 0.12);
	color: #4a4e66;
}

.property-index-link p {
	font-style: normal;
	font-weight: 450;
	font-size: 13px;
	line-height: 16px;
	color: #9fa2b8;
	font-family: "Circular Std Book";
}

.property-index-link a {
	font-style: normal;
	font-weight: 450;
	font-size: 14px;
	line-height: 18px;
	text-decoration: underline !important;
	color: #3864e4;
	font-family: "Circular Std Book";
}

.modal-content {
	border-radius: 20px !important;
	border: none !important;
}

.modal-wrapper .dialog-heading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #131521;
}

.modal-wrapper .modal-body {
	padding: 10px 0;
	background: #ffffff;
	box-sizing: border-box;
}

.modal-wrapper .price-index-link {
	font-family: "Circular Std Book";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: #a9a9a9;
	margin-right: 8px;
}

.modal-wrapper .price-index-link a {
	font-family: "Circular Std Book";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-decoration: underline !important;
	color: #3864e4;
}

.modal-wrapper .modals-body-content {
	flex: 0 0 65%;
	max-width: 65%;
	padding: 0;
	padding-left: 30px;
	padding-top: 18px;
}

.modal-wrapper .map-wrapper {
	flex: 0 0 35% !important;
	max-width: 35% !important;
	padding: 0;
}

.modal-button-wrapper {
	margin-top: 28px;
	padding-bottom: 18px;
	display: flex;
	gap: 16px;
}

.modal-button-wrapper .addPropert-btn button {
	background: #000000;
	border-radius: 10px;
	padding: 12px 30px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #ffffff;
	font-family: "CircularStd";
}

.modal-button-wrapper .addPropert-btn button:hover .btn-plusIcon {
	transform: none;
}

.search-dropdowns {
	-webkit-box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
	-moz-box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
	box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
	background: #ffffff;
	border-radius: 10px;
	padding-right: 10px;
}

.search-loading-state {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 15px 24px;
	width: 100%;
	margin-top: 5px;
}

.search-loading-state .search-loading-text {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.2px;
	color: #373f41;
	font-family: "Circular Std Book";
}

.search-loading-state .spinning-loader img {
	width: 40px;
}

@media (max-width: 992px) {
	.modal-dialog {
		max-width: 100% !important;
		/* height: 100vh; */
	}
}

@media (max-width: 700px) {
	.modal-wrapper .row {
		flex-direction: column;
	}

	.modal-dialog {
		top: 47%;
		height: 85vh;
	}

	.property-model .row {
		flex-direction: column-reverse;
	}

	.modal-header .close {
		margin: -30px -16px -1rem auto !important;
	}

	.modal-wrapper .map-wrapper,
	.modal-wrapper .modals-body-content {
		flex: 0 0 100% !important;
		max-width: 100% !important;
		padding-left: 0;
	}

	.modal-wrapper .modal-body {
		padding: 16px;
	}

	.modal-dialog {
		padding: 16px !important;
	}

	.gm-iv-address {
		display: none;
	}
}

@media (max-width: 480px) {
	.modal-wrapper .modal-body {
		padding: 0px;
		margin-top: 0px;
	}

	.modal-wrapper .dialog-heading {
		font-size: 16px;
	}

	.modal-wrapper .modals-body-content {
		padding-top: 10px;
	}

	.modal-dialog {
		padding: 16px !important;
	}

	.addPropert-btn,
	.modal-button-wrapper .addPropert-btn button {
		width: 100%;
	}

	.modal-button-wrapper {
		flex-direction: column;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		width: 88%;
		margin: 0 auto;
		z-index: 99;
	}
}

.addressfilter__input input {
	opacity: 1 !important;
}
