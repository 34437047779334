.hero-sec {
  width: 100%;
  padding-top: 120px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  z-index: 1;
  background: #ffffff;
}

.Getcertifiedcard {
  padding-left: 30px;
  padding-right: 30px;
}

.hero-contain {
  max-width: 1270px;
  margin: 0 auto;
}

.hero-wrapper {
  display: flex;
  justify-content: space-between;
  background: #f6f7fd;
  border-radius: 24px;
  min-height: 604px;
  position: relative;
  z-index: 1;
}

.hero-wrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  /* background-image: url(../Images/hero-image.png); */
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
  z-index: -1;
  background-size: auto;
}

/* position: absolute;
width: 1270px;
height: 604px;
left: 205px;
top: 109px;

background: #F6F7FD;
border-radius: 24px; */

.hero-left-side,
.hero-right-side {
  max-width: 100%;
  width: 100%;
}

.hero-left-side {
  padding-left: 80px;
  padding-top: 50px;
  position: relative;
  max-width: 560px;
}

/* .react-rotating-text-cursor {
	animation: none !important;
	display: none !important;
} */

.hero-left-side h1 span {
  display: inline-table;
}

/* .hero-left-side::after {
	content: "";
	position: absolute;
	top: 26%;
	right: -83px;
	background-image: url(../Images/vs-img.svg);
	background-repeat: no-repeat;
	width: 60px;
	height: 60px;
	z-index: 999;
} */

.hero-right-side {
  margin-left: 54px;
}

.hero-left-side h1,
.hero-right-side h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 70px;
  letter-spacing: 2px;
  color: #131521;
  font-family: "Montserrat";
}

.hero-left-side p,
.hero-right-side p {
  color: #4c506b;
  font-style: normal;
  font-family: "Circular Std Book";
  font-weight: normal;
  font-size: 20px;
  margin-top: 30px;
  line-height: 33px;
  text-align: left;
}

.hero-left-side h1 .deco-line,
.hero-right-side h1 .deco-line-2 {
  position: relative;
}

.get-certified-card {
  margin-top: 120px;
}

.get-certified-card-wrapper {
  background: #ffffff;
  box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.03);
  width: 100%;
  padding: 40px 60px 100px 60px;
  position: relative;
  overflow: hidden;
}

.get-certified-card-wrapper h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  color: #131521;
  margin-bottom: 25px;
}

.pronunce-text {
  margin-left: 32px;
  margin-bottom: -10px;
}

.hero-left-side svg {
  position: relative;
  top: 10px;
  margin: 0 2px;
}

.get-certified-text,
.get-certified-button,
.step-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-get-certified-text {
  flex-direction: column;
}

.box-top-text {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #131521;
  font-family: "Circular Std Book";
}

.get-certified-card-paragraph p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #31354e;
  margin-top: 25px;
  font-family: "Circular Std Book";
}

.card-get-certified-title .box-top-text {
  margin-bottom: 20px;
  font-weight: bold;
}

.step-text img {
  position: relative;
  top: 17px;
}

.step-text span {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #a9a9a9;
  font-family: "CircularStd";
  font-style: italic;
  margin-right: 25px;
  margin-left: 6px;
  width: 47px;
}

.get-certified-card .blue-btn {
  text-decoration: none;
  display: block;
  font-size: 14px;
  font-family: "CircularStd";
  font-weight: bold;
  color: rgb(255, 255, 255);
  line-height: 1.2;
  outline: none;
  background: none;
  border: none;
  width: 203px;
  height: 60px;
  background: #3864e4;
  border-radius: 70px;
  margin-right: 22px;
  transition: all 0.4s ease-in-out;
}

.get-certified-card .blue-btn:hover {
  background: #204ccf;
  color: #fff;
  box-shadow: 0px 10px 30px 0px rgba(74, 62, 243, 0.3);
  cursor: pointer;
  outline: none;
}

.rotating-text {
  color: #3864e4 !important;
}

.react-rotating-text-cursor {
  color: #131521 !important;
  font-weight: 300;
}

.text-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 26px 21px;
  position: relative;
}

.text-box::after {
  content: "";
  position: absolute;
  border: solid rgba(0, 0, 0, 0.1);
  background: white;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -6px;
  top: 50%;
}

.text-box p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  color: #31354e;
  max-width: 407px;
  font-family: "Circular Std Book";
}

.skip-to-links {
  margin-top: 22px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.skip-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skip-to-links p {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #5b607d;
  font-family: "Circular Std Book";
  min-width: 48px;
}

.skip-to-links a {
  position: relative;
  z-index: 1;
  background: #ffffff;
  border: 1px solid #e3e4e8;
  border-radius: 4px;
  padding: 7px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #3864e4;
  font-family: "Circular Std Book";
  transition: all 0.3s ease-in-out;
}

.skip-to-links a:hover {
  color: #0b2b8b;
  border: 1px solid #0b2b8b;
}

.skip-to-links a::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 27px;
  left: 0;
  width: 5px;
  margin: 0 auto;
  right: 0;
  border: 5px solid #ffffff;
  transform: rotate(45deg);
  box-shadow: 1px 1px 0 0 #e3e4e8;
  transition: all 0.3s ease-in-out;
}

.skip-to-links a:hover::after {
  box-shadow: 1px 1px 0 0 #0b2b8b;
}

@media only screen and (max-width: 1140px) {
  .hero-left-side h1,
  .hero-right-side h1 {
    font-size: 5.5vw;
    line-height: 7.1vw;
  }
}

@media only screen and (max-width: 1040px) {
  .hero-wrapper::after {
    opacity: 0.15;
  }

  .hero-left-side p {
    color: #4c506b !important;
  }
}

@media only screen and (max-width: 840px) {
  .hero-wrapper,
  .get-certified-text {
    flex-direction: column;
  }

  .hero-left-side,
  .hero-right-side {
    margin: 0;
    width: 650px;
    margin: 0 auto;
  }

  .hero-left-side::after {
    top: inherit;
    bottom: -39%;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  /* .hero-left-side h1,
	.hero-right-side h1 {
		font-size: 70px;
		line-height: 84px;
	} */

  .hero-right-side {
    margin-top: 150px;
  }

  .hero::before {
    width: 100%;
    height: 62%;
    bottom: 0;
    right: 0;
    left: 0;
    top: inherit;
  }

  .get-certified-card-wrapper {
    padding: 50px 30px;
  }

  .get-certified-button {
    padding-bottom: 20px;
  }

  .text-box::after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    right: 161px;
    left: inherit;
    top: -6px;
  }

  .hero-left-side {
    padding-left: 30px;
    padding-right: 30px;
    margin: inherit;
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 690px) {
  .hero-left-side::after {
    bottom: -56%;
  }

  .hero-right-side h1 {
    margin-top: 60px;
    color: red !important;
  }

  .hero-wrapper::after {
    width: 100%;
    opacity: 0.15;
    background-size: cover;
    /* height: 55vh; */
  }
}

@media only screen and (max-width: 580px) {
  .hero-sec {
    padding-top: 100px;
  }

  /* .hero-left-side {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 30px;
		margin-top: -37px;
	} */

  /* .hero-left-side {
    position: relative;
  } */

  .searchproperty {
    padding-bottom: 10px;
  }

  .hero-left-side::after {
    bottom: -68%;
  }

  .hero-left-side h1,
  .hero-right-side h1 {
    font-size: 9vw;
    line-height: 12vw;
  }

  .hero-right-side {
    margin-top: 170px;
  }

  .hero::before {
    height: 65%;
  }

  .get-certified-card-wrapper {
    padding: 50px 15px;
  }

  .box-top-text {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 50px;
  }

  /* .Header {
		padding: 20px 15px;
	} */

  .hero-sec,
  .Getcertifiedcard {
    padding-left: 15px;
    padding-right: 15px;
  }

  .get-certified-card {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 480px) {
  .hero-right-side h1 .deco-line-2::after {
    width: 80vw;
    bottom: -3.3vw;
  }

  /* .hero-left-side {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 30px;
		margin-top: -37px;
	} */
  .hero-left-side {
    padding-left: 20px;
    padding-top: 35px;
    position: relative;
  }

  .skip-to-links a {
    font-size: 12px;
    padding: 7px;
  }

  .hero_scroll_listitems {
    padding-top: 25px;
  }

  .hero-wrapper {
    min-height: 600px;
  }

  .text-box::after {
    right: 30.5vw;
  }

  .pronunce-text {
    margin: 0;
  }

  .pronunce-text img {
    width: 200px;
  }

  .text-box p {
    font-size: 13px;
    line-height: 20px;
  }

  .text-box {
    padding: 20px;
  }

  .get-certified-card .blue-btn {
    width: 170px;
    height: 50px;
  }

  .hero-left-side p,
  .hero-right-side p {
    font-size: 18px;
    line-height: 28px;
  }

  .hero-left-side p {
    margin-bottom: 0px;
  }

  .step-text span {
    font-size: 14px;
    margin-right: 10px;
  }

  .hero-left-side::after {
    bottom: -70%;
  }
}

@media only screen and (max-width: 430px) {
  .hero::before {
    height: 68%;
  }

  .hero-right-side {
    margin-top: 90px;
  }

  .hero-left-side::after {
    bottom: -44%;
  }

  .text-box::after {
    right: 28.5vw;
  }
}

@media only screen and (max-width: 420px) {
  .hero-left-side::after {
    bottom: -50%;
  }
}

@media only screen and (max-width: 412px) {
  .hero-left-side::after {
    bottom: -51%;
  }
}

@media only screen and (max-width: 390px) {
  .hero-left-side::after {
    bottom: -57%;
  }
}

@media only screen and (max-width: 375px) {
  .hero::before {
    height: 70%;
  }

  .hero-left-side::after {
    bottom: -47%;
  }
}

@media only screen and (max-width: 360px) {
  .hero::before {
    height: 70%;
  }

  .hero-left-side::after {
    bottom: -53%;
  }
}
