.Dashboard {
	background: rgba(254, 249, 245, 0.38);
	width: 100%;
	height: 100%;
	min-height: 100%;
	position: relative;
	overflow: hidden;
}

.navbar-bg {
	z-index: 10;
	height: auto;
	padding: 0px;
}

.Profile-image .btn-primary {
	padding-right: 30px;
}
.Profile-image img {
	max-width: 45px;
	border-radius: 90px;
}
.dash-logo img {
	padding-top: 12px;
	width: 100px;
}

.dash-logo {
	padding-top: 0px;
	padding-bottom: 0px;
	border-right: 1px solid rgb(245, 245, 245);
	height: 82px;
	padding: 14px 100px;
	margin-right: 35px;
}

.formGroup {
	position: relative;
}

.search-btn {
	position: absolute;
	background: none;
	border: none;
	outline: none;
	padding: none;
}

.search-btn:hover,
.search-btn:focus {
	background: none;
	border: none;
	outline: none;
	padding: none;
	box-shadow: none;
}

.form-control {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0 40px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(185, 187, 193);
	background-clip: padding-box;
	border: none;
	outline: none;
	border-radius: 0;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
}

.form-inline .form-control {
	display: inline-block;
	width: 260px;
	height: 40px;
	background: #e9ecf3;
	font-family: "Circular Std Book";
	border-radius: 60px;
	color: #000;
	font-size: 13px;
	vertical-align: middle;
}

.form-inline .form-control::placeholder {
	color: #9fa2b8;
}

@media (min-width: 1024px) {
	.form-inline .form-control {
		display: inline-block;
		width: 260px;
		border-radius: 60px;
		vertical-align: middle;
	}
}

@media (max-width: 1023px) {
	.form-inline .form-control {
		display: inline-block;
		width: 300px;
		vertical-align: middle;
	}
}

.form-control:active,
.form-control:focus {
	border: none;
	outline: none;
	box-shadow: none;
}

.list-group {
	width: 100%;
	border-bottom: 1px solid #242739;
	margin: 0 auto;
}

.list-group-item:first-child {
	margin-top: 20px;
}

.list-group-item:last-child {
	margin-bottom: 20px;
}

.list-group:last-child {
	border-bottom: none;
	/* padding-bottom: 120px; */
}

.sidebar-menu-nav:hover {
	color: #f9f9f9;
	text-decoration: none;
}

.sidebar-sec {
	background-color: #131521;
	/* box-shadow: 2px 0px 8px 0px rgba(19, 25, 35, 0.15); */
	height: 100%;
	width: 270px;
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	overflow-x: hidden;
}

.logo-dashbaord {
	margin-top: 35px;
	padding-left: 30px;
	margin-bottom: 40px;
}

.list-group-item {
	border: none;
	padding: 0 !important;
	transition: 0.3s;
	background-color: transparent;
	position: relative;
	z-index: 10;
	background: #131521 !important;
	border-radius: none;
	border: none !important;
	transition: 0.3s ease-in-out;
}

.list-group-item a {
	display: block;
	padding: 13px 0;
	font-style: normal;
	font-weight: normal;
	font-family: "Circular Std Book";
	font-size: 15px;
	line-height: 19px;
	color: #98999f;
	text-decoration: none;
	border-radius: none;
	width: 100%;
	padding: 14px 15px;
}

.no-hover:hover {
	background-color: transparent !important;
	cursor: default !important;
}

.table-responsive .header:hover,
.table-responsive .table-empty-state-2:hover {
	background-color: #fff;
	cursor: inherit;
}

.table-responsive tr {
	transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.table-responsive tr:hover {
	background-color: #f7f8ff;
	cursor: pointer;
}

.list-group-item a:hover {
	text-decoration: none;
}

.sidebar-menu {
	padding-left: 8px;
}

.list-group-item:hover {
	position: relative;
	width: 100%;
	color: #fff;
}

.list-group-item .active {
	background: red;
	background: #373a4e;
	border-radius: 10px;
	color: white;
}

.navbar__link--active {
	width: 100%;
	position: absolute;
	background-color: #373a4e;
	color: #fff;
	border-radius: 10px;
	padding: 0 15px;
	position: relative;
	z-index: 999;
}

.list-group-item span.active {
	color: #fff;
}

.list-group-item:hover span.sidebar-menu {
	color: #fff;
}

.row {
	margin-right: 0px;
	margin-left: 0px;
}

.DashContent {
	width: calc(100%);
	padding: 50px 50px 130px 320px;
	margin: 0 auto;
}

.nav-heading {
	padding: 12px 30px;
	color: #f3f3f3;
	position: relative;
	font-size: 12px;
	font-family: "Montserrat";
}

.auction-navbar,
.auction-topbar {
	display: flex;
	justify-content: center;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 270px);
	margin-left: 270px;
	padding-top: 40px;
}

.auction-navbar .createauction-btn button {
	background: #000000;
	border-radius: 33px;
	color: #ffffff;
	padding: 11px 25px;
	font-weight: bold;
	font-size: 15px;
	line-height: 19px;
	font-family: "CircularStd";
	border: none;
	outline: none;
	position: relative;
	transition: all 0.6s ease-in-out;
	transition: 0.3s ease-in-out;
}

.auction-navbar .createauction-btn button:hover {
	background: #202020;
	color: #fff;
	box-shadow: 0px 10px 30px 0px rgb(20 20 20 / 30%);
	cursor: pointer;
	outline: none;
}

.auction-navbar .dashTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-right: 50px;
}

.dashTitle-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 50px;
}

.dashTitle h4 {
	font-style: normal;
	font-weight: normal;
	font-size: 32px;
	line-height: 40px;
	color: #131521;
	font-family: "CircularStd";
	font-weight: 600;
}

.logout-btn .log-link {
	/* padding: 0 25px; */
	margin-top: 20px;
	width: 85%;
	display: flex;
	align-items: center;
	margin: 0 auto;
	padding-top: 33px;
	margin-bottom: 33px;
	transition: all 0.6s ease-in-out;
	transition: 0.3s ease-in-out;
	border-top: 1px solid #242739;
}

.logout-btn .log-link span {
	font-size: 15px;
	line-height: 19px;
	font-family: "Circular Std Book";
	color: #ce3939;
	margin: 0 5px;
}

.logout-btn .log-link span:hover {
	color: #921515;
}

.accordion__button {
	border-radius: 0 !important;
	border-bottom: 1px solid #26293a !important;
	margin-bottom: 0 !important;
	background-color: #181a2a !important;
	color: white !important;
	font-size: 13px;
}

.accordion__panel {
	padding: 0 15px !important;
}

.auction-navbar .dashTitle h4,
.auction-topbar .dashTitle h4 {
	padding-left: 50px;
}

.addPropert-btn button {
	background: #3864e4;
	color: #fff;
	padding: 13px 30px;
	font-weight: bold;
	font-size: 15px;
	line-height: 19px;
	border-radius: 50px;
	font-family: "CircularStd";
	border: none;
	outline: none;
	position: relative;
	transition: all 0.6s ease-in-out;
	transition: 0.3s ease-in-out;
}

.addPropert-btn button:hover {
	background-color: #1e6fc1;
	box-shadow: 0px 8px 35px 0px rgba(51, 136, 223, 0.2);
}

.addPropert-btn {
	position: relative;
}

.btn-plusIcon {
	margin-right: 10px;
	transition: 0.3s ease-in-out;
}

.addPropert-btn button:hover .btn-plusIcon {
	transform: rotate(-90deg);
}

.PropertyCardGroup {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-columns: repeat(auto-fill, minmax(273px, 1fr));
	width: 100%;
	grid-gap: 43px;
	margin-top: 35px;
}

.PropertyCard {
	background-color: rgb(255, 255, 255);
	-webkit-box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.06);
	box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.06);
	/* border-radius: 5px; */
	-webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	-o-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.PropertyCard:hover {
	-webkit-box-shadow: 0px 6px 45px 0px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.1);
}

.PropertyCard img {
	width: 100%;
	padding: 15px;
	position: relative;
	z-index: 1;
}

.card-cta {
	position: relative;
}

.card-cta span {
	position: absolute;
	right: 15px;
	bottom: 15px;
	z-index: 9;
	background: rgba(56, 100, 228, 0.65);
	backdrop-filter: blur(5px);
	padding: 7px 12px;
	font-weight: normal;
	font-size: 11px;
	line-height: 14px;
	text-align: center;
	color: #ffffff;
	font-family: "Circular Std Book";
}

.PropertyCard-text {
	padding: 15px 30px 30px;
}

.PropertyCard-text span {
	background-color: rgba(248, 122, 60, 0.2);
	color: rgb(248, 122, 60);
	font-size: 10px;
	font-family: "Montserrat";
	text-transform: uppercase;
	line-height: 1.2;
	text-align: center;
	padding: 5px 10px;
	border-radius: 11px;
	margin-bottom: 20px;
	font-weight: 600;
}

.PropertyCard-text h3 {
	font-size: 16px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.2;
	padding: 0 0 20px 0;
	font-weight: bold;
	font-size: 24px;
	line-height: 30px;
	color: #31354e;
	font-family: "CircularStd";
}

.PropertyCard-lists {
	position: relative;
}

.PropertyCard-lists li {
	list-style: none;
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(131, 133, 135);
	line-height: 1.2;
	padding-left: 28px;
	position: relative;
}

.PropertyCard-lists li:nth-child(1) {
	background-image: url(../Images/address-state-icon1.svg);
	background-repeat: no-repeat;
}

.PropertyCard-lists li:nth-child(2) {
	margin-top: 18px;
	background-image: url(../Images/address-state-icon.svg);
	background-repeat: no-repeat;
}

@media only screen and (max-width: 900px) {
	.sidebar-sec {
		/* background-color: rgb(37, 44, 65); */
		box-shadow: 2px 0px 8px 0px rgba(19, 25, 35, 0.15);
		height: 100%;
		width: 300px;
		position: fixed;
		z-index: 999;
		top: 82px;
		left: 0;
		overflow-x: hidden;
	}

	.DashContent {
		padding: 50px 40px 70px 340px;
	}
}

@media only screen and (max-width: 900px) {
	.sidebar-sec {
		height: 100%;
		width: 100%;
		position: fixed;
		z-index: 999;
		display: none;
		top: 0;
	}

	#Dashboard .nav-right-side {
		width: 85%;
	}

	.dashTitle-bar {
		margin-top: 0;
	}

	.sidebar-sec.in {
		display: block;
	}

	.list-group-item1.active {
		margin-top: 150px;
	}

	.DashContent {
		width: calc(100%);
		padding: 50px 30px 30px 30px;
		margin: 0 auto;
	}
}

.notificationGroup {
	float: right;
}

.Notify-bell {
	background: #e9ecf3;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	margin-left: 25px;
	margin-right: 17px;
	justify-content: center;
	border-radius: 50%;
}

.Notify-bell .btn-primary {
	color: transparent;
	background-color: transparent;
	border-color: transparent;
	background-image: url(../Images/bell-icon.svg);
	background-repeat: no-repeat;
	outline: none;
	border: none;
	width: 15px;
	height: 17px;
	position: absolute;
	left: 13px;
}

.Notify-bell .btn-primary::after {
	content: "";
	position: absolute;
	border: 4px solid #3864e4;
	top: -9px;
	right: -2px;
	border-radius: 100px;
}

.Notify-bell .show {
	position: absolute !important;
	top: 27px !important;
}

.Notify-bell .btn-primary:active,
.Notify-bell .btn-primary:focus {
	color: transparent;
	background-color: transparent;
	border-color: transparent;
	outline: none;
	border: none;
	width: 0px;
	height: 21px;
	box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	color: transparent;
	background-color: transparent;
	border-color: transparent;
	outline: none;
	border: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: none;
}

.dropdown-menu.show {
	top: 20px;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1.5rem;
	padding: 16px 30px;
	clear: both;
	font-size: 13px;
	font-weight: 300;
	color: #212529;
}

.nav-wrapper {
	display: flex;
	width: 100%;
}

.nav-right-side {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
}

.dropdown-menu {
	border: none;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 0.5rem 4rem rgba(0, 0, 0, 0.11), 0 10px 20px rgba(0, 0, 0, 0.05),
		0 2px 3px rgba(0, 0, 0, 0.06);
}

.dropdown-menu-right.show {
	top: 28px;
	position: relative;
}

.veiw-all-noti {
	text-align: center;
	font-size: 13px;
	font-weight: bold;
}

.Profile-image .btn-primary {
	color: transparent;
	background-color: transparent;
	border-color: transparent;
	outline: none;
	border: none;
}

span.profile-name {
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.2;
	padding-left: 10px;
}

.Profile-image .show {
	position: absolute !important;
	top: 55px !important;
	left: -110px !important;
}

/* Hamburger styling */

#menu_button {
	width: 30px;
	overflow: hidden;
	position: absolute;
	right: 30px;
	top: 40px;
	display: none;
	z-index: 11111111;
	z-index: 99999999;
}

#menu_checkbox {
	display: none;
}

#menu_label {
	position: relative;
	display: block;
	height: 16px;
	cursor: pointer;
}

#menu_label:before,
#menu_label:after,
#menu_text_bar {
	position: absolute;
	left: 0;
	width: 100%;
	height: 1px;
	background-color: #202020;
}

#menu_label:before,
#menu_label:after {
	content: "";
	transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) left;
	color: #fff;
}

#menu_label:before {
	top: 0;
	color: #fff;
}

#menu_label:after {
	top: 7px;
	color: #fff;
}

#menu_text_bar {
	top: 14px;
	color: #fff;
}

#menu_text_bar:before {
	content: "  Close";
	position: absolute;
	top: 5px;
	right: 0;
	left: 0;
	color: #fff;
	font-size: 11px;
	font-weight: bold;
	font-family: "Montserrat";
	text-align: center;
}

#menu_checkbox:checked + #menu_label:before {
	left: -39px;
	color: #fff;
}

#menu_checkbox:checked + #menu_label:after {
	left: 39px;
	color: #fff;
}

#menu_checkbox:checked + #menu_label #menu_text_bar:before {
	animation: moveUpThenDown 0.8s ease 0.2s forwards,
		shakeWhileMovingUp 0.8s ease 0.2s forwards,
		shakeWhileMovingDown 0.2s ease 0.8s forwards;
	color: #fff;
}

@keyframes moveUpThenDown {
	0% {
		top: 0;
	}
	50% {
		top: -18px;
	}
	100% {
		top: -12px;
	}
}

@keyframes shakeWhileMovingUp {
	0% {
		transform: rotateZ(0);
	}
	25% {
		transform: rotateZ(-10deg);
	}
	50% {
		transform: rotateZ(0deg);
	}
	75% {
		transform: rotateZ(10deg);
	}
	100% {
		transform: rotateZ(0);
	}
}

@keyframes shakeWhileMovingDown {
	0% {
		transform: rotateZ(0);
	}
	80% {
		transform: rotateZ(3deg);
	}
	90% {
		transform: rotateZ(-3deg);
	}
	100% {
		transform: rotateZ(0);
	}
}

@media (max-width: 900px) {
	#menu_button {
		display: block;
	}

	.formGroup,
	.dash-logo {
		display: none;
	}

	.Notify-bell {
		left: 30px;
	}

	.Profile-image {
		left: 80px;
	}

	.auction-navbar {
		margin-left: 0;
		width: calc(100% - 50px);
		padding-top: 25px !important;
	}

	.dropdown-menu-right.show {
		top: 50px !important;
	}

	.Notify-bell {
		left: 60px;
	}

	#menu_button {
		top: 40px;
	}
}

/******* Add Property Styling ********/

.AddProperty {
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
	border-radius: 10px;
	width: 100%;
	height: 655px;
	margin-top: 0px !important;
}

.AddPropertyGroup {
	display: grid;
	grid-template-columns: 340px 77%;
	grid-template-columns: 1fr 3fr;
}

.AddProperty-ls {
	background-color: rgba(246, 247, 251, 0.6);
	height: 655px;
	padding: 50px;
}

.AddProperty-t h5 {
	font-size: 18px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	font-weight: bold;
	line-height: 1.5;
	max-width: 300px;
}

.AddProperty-steps {
	padding: 95px 0;
}

.AddProperty-steps ul li {
	list-style: none;
	font-size: 14px;
	font-family: "Montserrat";
	color: rgb(139, 148, 178);
	line-height: 1.2;
	position: relative;
	padding-left: 50px;
	margin-top: 40px;
	font-weight: 500;
	counter-increment: my-awesome-counter;
}

.AddProperty-steps ul li.active {
	color: rgb(47, 51, 65);
}

.AddProperty-steps ul li:first-child {
	margin-top: 0px;
}

.AddProperty-steps ul li::after {
	content: counter(my-awesome-counter);
	color: rgb(139, 148, 178);
	font-size: 14px;
	position: absolute;
	left: 0;
	top: -10px;
	width: 36px;
	height: 36px;
	font-weight: 500;
	border-radius: 50%;
	background-color: rgb(239, 239, 249);
	position: absolute;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.AddProperty-steps ul li.active::after {
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
	color: rgb(47, 51, 65);
}

.AddProperty-steps ul li.active-tick::after {
	content: url(../Images/addpropert-tick.png);
	background-color: transparent;
}

.AddProperty-rs {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 0 40px;
}

.AddProperty-rs-Group {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 50px;
}

.property-type-tags h6 {
	font-size: 14px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.2;
	font-weight: 500;
	padding-bottom: 15px;
}

.property-tags button {
	border: 1px solid rgb(225, 227, 230);
	border-radius: 17px;
	background-color: rgb(255, 255, 255);
	font-size: 14px;
	font-family: "Montserrat";
	color: rgba(78, 90, 107, 0.851);
	line-height: 1.2;
	padding: 10px 15px;
	margin-right: 10px;
	margin-bottom: 10px;
}

.property-tags button:focus,
.property-tags button.active {
	background-color: rgb(51, 136, 223);
	color: #fff;
}

.Ap-submitted-btn {
	text-align: center;
	padding-top: 50px;
}

.Ap-submitted-btn button {
	background-image: -moz-linear-gradient(
		-60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	background-image: -ms-linear-gradient(
		-60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	background-image: linear-gradient(
		-60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	padding: 8px 35px;
	/* width: 154px; */
	height: 44px;
	border-radius: 50px;
	font-size: 14px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	font-weight: bold;
	text-align: center;
	border: none;
	outline: none;
}

.AddProperty-form {
	width: 80%;
	max-width: 80%;
}

.AddProperty-inputs {
	padding: 0;
}

.AddProperty-inputs li {
	list-style: none;
	margin-top: 30px;
}

.AddProperty-inputs li:first-of-type {
	margin-top: 0px;
}

/* .AddProperty-inputs {
	width: 467px;
} */

ul.AddProperty-inputs li input,
ul.AddProperty-inputs .css-yk16xz-control {
	border: none;
	outline: none;
	width: 100%;
	min-height: 44px;
	font-size: 14px;
	color: #202020;
	outline: none;
	background-color: rgb(255, 255, 255);
	border: 1px solid rgb(225, 227, 230);
	border-radius: 30px;
	padding-bottom: 2px;
	padding: 0;
}

.css-1wa3eu0-placeholder {
	font-size: 14px;
	/* padding: 0 10px; */
}

.AddProperty-inputs .css-1pahdxg-control {
	border-radius: 30px !important;
	min-height: 44px;
	padding: 0;
}

.AddProperty-inputs .css-1wa3eu0-placeholder {
	margin: 0;
}

ul.AddProperty-inputs li input:focus {
	border: 1px solid #4756f7;
	-webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.15);
}

.AddProperty-inputs .input-label {
	color: rgb(46, 50, 56);
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #131521;
	font-family: "CircularStd";
	font-weight: 500;
	margin-bottom: 12px;
}

@media (max-width: 1400px) {
	/* .AddProperty-inputs {
		width: 100%;
	} */
}

@media (max-width: 1140px) {
	.AddPropertyGroup {
		display: flex;
		flex-direction: column;
	}

	.AddProperty,
	.AddProperty-ls {
		height: auto;
	}

	.AddProperty-inputs {
		width: 100%;
	}

	.AddProperty-rs,
	.AddProperty-ls {
		padding: 30px;
	}

	.AddProperty-illustration {
		display: none;
	}

	.AddProperty-steps {
		padding: 0px;
		padding-top: 40px;
	}
}

.AuctionPage {
	display: flex;
}

.auction-page-rightside {
	margin-left: 30px;
}

.AuctionCard {
	padding: 10px;
	margin-bottom: 30px;
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
	border-radius: 15px;
}

.auction-img img {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	width: 100%;
}

.AuctionCardText {
	padding: 30px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.AuctionCardTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 25px;
}

.AuctionCardTitle .property-location {
	display: flex;
}

.AuctionCardTitle .property-location img {
	margin-right: 10px;
}

.AuctionCardTitle .property-location p {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #5b607d;
	font-family: "Circular Std Book";
}

.AuctionCardTitle h4 {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	font-family: "Montserrat";
	color: #131521;
}

.AuctionCardSubGroup {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 45px;
}

.acsg-2 {
	margin-top: 30px;
}

.AuctionSubTitle {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #9fa2b8;
	font-family: "Circular Std Book";
	padding-bottom: 4px;
}

.AuctionCardSubWrapper span {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #131521;
	font-family: "Circular Std Book";
}

span.acsg-amount {
	color: #3864e4;
	font-weight: 600;
}

.AuctionDoubleCard {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 50px;
	margin-top: 30px;
}

.SubmitBidCardGroup {
	padding: 20px 67px 30px 20px;
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
	border-radius: 15px;
}

.SubmitBidCardTitle {
	display: flex;
	justify-content: space-between;
	margin: 0px 0 30px 0;
}

.ExistingBidCard .SubmitBidCardTitle {
	margin: 30px 30px 15px 30px;
}

.Submit-lm-btn {
	text-align: right;
	display: flex;
	justify-content: flex-end;
}

.SubmitBidCardHeading {
	font-size: 16px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	font-weight: 600;
	line-height: 1.2;
}

.SubmitBidCardTimer {
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(131, 133, 135);
	line-height: 1.2;
}

.SubmitBidCardText span {
	color: #2e3238;
	font-weight: 500;
}

.Submit-lm-btn {
	float: right;
	border-style: solid;
	border-width: 1px;
	border-color: rgb(131, 133, 135, 0.302);
	border-radius: 4px;
	background-color: rgba(51, 136, 223, 0);
	color: #202020;
	position: relative;
	top: -16px;
}

.action-ip-1,
.action-ip-2 {
	max-width: 260px;
	margin: 0;
	margin-top: 0px;
}

.action-ip-1 .input-range__track,
.action-ip-2 .input-range__track {
	background: #f8f9f9 !important;
}

.action-ip-2 .input-range__slider {
	border: 1px solid #5ad4ee !important;
}

.sbc-checkmark label:hover {
	cursor: pointer;
}

.action-ip-1 .input-range__track--active {
	background: linear-gradient(
		250.15deg,
		#9897eb 15.63%,
		#7e7dda 73.48%
	) !important;
}

.action-ip-2 .input-range__track--active {
	background: linear-gradient(
		51.13deg,
		#41c4e0 -1.42%,
		#60dcf7 80.84%
	) !important;
}

.sb-slider h6 {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #131521;
	margin-bottom: 10px;
	font-family: "Circular Std Book";
}

.input-range__label-container {
	font-size: 13px !important;
}

.action-ip-1 .equity-input-text,
.action-ip-2 .equity-input-text {
	margin-bottom: 0;
}

.sbc-checkmark {
	margin-top: 60px;
	max-width: 100%;
}

.sbc-checkmark .custom-control-label {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
	color: #5b607d;
	font-family: "Circular Std Book";
}

.sbc-checkmark .custom-control-label a,
.sbc-btn-link a {
	text-decoration: none;
	color: #e4ad24;
}

.sbc-btn-link a {
	color: #3864e4;
}

.equity-input .input-range__label--value span {
	top: 0;
}

.sbc-btn-link a {
	border-bottom: 1px solid #3864e4;
	font-family: "Circular Std Book";
}

.sbc-buttons {
	display: flex;
	align-items: center;
	margin-top: 30px;
}

.sbc-btn-link {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
	color: #5b607d;
	font-family: "Circular Std Book";
}

.sbc-btn Button {
	text-decoration: none;
	display: block;
	font-size: 15px;
	font-family: "CircularStd";
	font-weight: bold;
	color: rgb(255, 255, 255);
	line-height: 1.2;
	outline: none;
	background: none;
	border: none;
	width: 171px;
	height: 45px;
	background: #3864e4;
	border-radius: 10px;
	margin-right: 30px;
	transition: all 0.4s ease-in-out;
}

.exb-table {
	/* margin: 1em; */
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	border-radius: 15px;
	padding-bottom: 15px;
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
}

.Tabheader div {
	font-size: 12px;
	font-family: "Montserrat";
	color: rgb(131, 133, 135);
	text-transform: uppercase;
	line-height: 1.2;
	font-weight: 500;
	/* padding: 6px 0; */
}

.Tabheader {
	padding: 12px 0;
	border-bottom: 1px solid rgb(237, 239, 245);
}

.exb-table .Tabheader,
.exb-table .row {
	display: -webkit-box;
	display: flex;
}

.exb-table .Tabheader div,
.exb-table .row div {
	-webkit-box-flex: 1;
	flex: 1;
	padding: 0 26px;
}

.Tabheader div {
	border-bottom: none;
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 14px;
	color: #9fa2b8;
	font-family: "CircularStd";
	font-weight: 500;
}

.Tab-body .row {
	border: 0px !important;
}

.Tab-body div {
	font-size: 14px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.2;
}

.Tab-body .row {
	padding: 15px 0;
	border-bottom: 1px solid rgb(237, 239, 245);
}
.Tab-body .row div:first-child {
	font-weight: 500;
}

.AuctionTimeCard,
.AuctionMiniCard-section {
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
	border-radius: 15px;
	padding: 30px;
	margin-bottom: 30px;
}

.AuctionTimeCard h3,
.AuctionMiniCardsGroup h3 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	padding-bottom: 20px;
	color: #5b607d;
}

.AuctionTimeboxes {
	display: flex;
	flex-wrap: wrap;
}

.AuctionTimeboxes .time-box {
	background: #f6faff;
	border-radius: 15px;
	width: 60px;
	height: 76px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}

.AuctionTimeboxes .time-box span {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	color: #131521;
	font-family: "CircularStd";
	font-weight: 500;
	text-align: center;
}

.AuctionTimeboxes .time-box p {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	color: #9fa2b8;
	margin-top: 2px;
	font-family: "Circular Std Book";
}

.AuctionMiniCards .AuctionCardwrapper {
	display: flex;
	align-items: center;
	background: #f6faff;
	border-radius: 15px;
	padding: 12px;
	margin-bottom: 15px;
}

.AuctionCardwrapper a {
	text-decoration: none;
}

.AuctionMiniCards .AuctionCardwrapper:last-child {
	margin-bottom: 0px;
}

.AuctionminiCardText {
	margin-left: 20px;
}

.AuctionminiCardText .AuctionminiCardTitle h4 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	color: #131521;
}

.AuctionminiCardText .AuctionCardSubWrapper {
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.AuctionminiCardText .AuctionCardSubWrapper h6 {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #9fa2b8;
	font-family: "Circular Std Book";
	padding: 0;
	margin-right: 4px;
}

.AuctionminiCardText .AuctionCardSubWrapper p {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #131521;
	font-family: "Circular Std Book";
}

.card-body {
	padding: 0px !important;
	border: 0px;
	background-color: #131521;
	background-clip: border-box;
	border: 0 !important;
	border-radius: 0;
}

.card {
	border: 0 !important;
	border-radius: 0 !important;
}

.card-header {
	border-radius: 0 !important;
	border-bottom: 1px solid #26293a !important;
	margin-bottom: 0 !important;
	background: #181a2a !important;
	color: white !important;
	font-size: 13px;
}

.card-header:hover {
	cursor: pointer;
}

.card-header:last-child {
	border-bottom: 0 !important;
}

@media (max-width: 1199px) {
	.AuctionDoubleCard {
		grid-template-columns: repeat(1, 1fr);
	}
	.AuctionPage {
		flex-direction: column;
	}

	.auction-page-rightside {
		margin-left: 0;
		margin-top: 30px;
	}
}

@media (max-width: 900px) {
	.AuctionDoubleCard {
		grid-template-columns: repeat(2, 1fr);
	}

	.AddProperty {
		margin-top: 0;
	}
}

@media (max-width: 767px) {
	.AuctionDoubleCard {
		grid-template-columns: repeat(1, 1fr);

		grid-gap: 10px;
	}

	#Dashboard .nav-right-side {
		width: 75%;
	}

	/* .Profile-image {
		left: 115px;
	} */
}

@media (max-width: 600px) {
	.DashContent {
		width: calc(100%);
		padding: 60px 15px 15px 15px;
		margin: 0 auto;
	}

	.AddProperty-form {
		width: 100%;
		max-width: 100%;
	}

	.Profile-image {
		left: 60px;
	}

	#menu_button {
		right: 20px;
		top: 34px;
	}

	.dashTitle h4 {
		font-size: 20px;
		line-height: 32px;
	}

	.auction-navbar .dashTitle h4,
	.auction-topbar .dashTitle h4 {
		padding-left: 15px;
	}

	.dropdown .btn-primary {
		width: 0px !important;
	}

	.Profile-image .btn-primary {
		padding-right: 0;
	}

	.auction-navbar {
		width: calc(100% - 30px);
	}

	.AuctionCardSubGroup {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 35px;
	}

	.action-ip-1,
	.action-ip-2 {
		max-width: 100%;
	}

	.AuctionCardText {
		padding: 30px 10px;
	}

	.AuctionTimeCard,
	.AuctionMiniCard-section {
		padding: 30px 20px;
	}

	#Dashboard .nav-right-side {
		width: 60%;
	}
}

@media (max-width: 560px) {
	.dropdown .btn-primary {
		width: 0px !important;
	}

	.equity-input {
		padding: 0 0;
	}

	#Dashboard .nav-right-side {
		width: 40%;
	}

	.auction-navbar .dashTitle {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding-right: 0px;
	}

	.auction-navbar .createauction-btn-2 button {
		width: 100% !important;
	}

	.auction-navbar .createauction-btn,
	.auction-navbar .createauction-btn-2 {
		margin-top: 20px;
		position: relative;
		top: 40px;
	}

	.auction-navbar,
	.auction-topbar {
		flex-direction: column;
	}

	.auction-navbar .createauction-btn,
	.auction-navbar .createauction-btn-2,
	.auction-navbar .createauction-btn a,
	.auction-navbar .createauction-btn button {
		width: 100%;
		display: flex;
		justify-content: center;
		position: relative;
		left: 6px;
		flex: none;
	}
}

@media (max-width: 480px) {
}

.wj-inputnumber input.wj-form-control {
	text-align: left !important;
	width: 100%;
}
