.issuances-table .table {
	display: inline-table;
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
	border-radius: 10px;
	margin-top: 60px;
}

.issuances-table .dashTitle-bar {
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
	border-radius: 10px 10px 0 0;
	padding: 30px 30px 10px 30px;
	max-width: 100%;
	width: 100%;
}

.issuances-table .dashTitle h4 {
	font-size: 22px;
	line-height: 28px;
	color: #131521;
	font-weight: 500;
}

.issuances-table .createauction-btn button {
	background: #000000;
	border-radius: 33px;
	color: #ffffff;
	padding: 11px 25px;
	font-weight: bold;
	font-size: 15px;
	line-height: 19px;
	font-family: "CircularStd";
	border: none;
	outline: none;
	position: relative;
	transition: all 0.6s ease-in-out;
	transition: 0.3s ease-in-out;
}

.issuances-table .table thead th {
	border: none;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	padding: 12px 30px;
	color: #9fa2b8;
	position: relative;
	z-index: 10;
	width: 30%;
	font-family: "CircularStd";
}

.issuances-table .table td {
	border-top: 1px solid #f4f5fa;
	padding: 15px 30px;
	vertical-align: middle;
	/* min-width: 170px; */
}

.issuances-table .table .property-col {
	display: flex;
	align-items: center;
	min-width: 300px;
}

.issuances-table .table .property-col img {
	border-radius: 50%;
	margin-right: 15px;
	width: 55px;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
}

.issuances-table .table .property-col h6 {
	font-size: 15px;
	line-height: 21px;
	color: #131521;
	font-family: "CircularStd";
	font-weight: 500;
}

.issuances-table .table .tag--active,
.issuances-table .table .tag--inreview,
.issuances-table .table .tag--success,
.issuances-table .table .tag--closed {
	font-weight: normal;
	font-size: 11px;
	line-height: 14px;
	text-align: center;
	color: #3864e4;
	font-family: "CircularStd";
	font-weight: 500;
	background: rgba(56, 100, 228, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 60px;
	padding: 5px 15px;
}

.issuances-table .table .tag--inreview {
	background: rgba(255, 174, 0, 0.15);
	color: #ffae00;
}

.issuances-table .table .tag--success {
	background: rgba(83, 182, 73, 0.12);
	color: #53b649;
}

.issuances-table .table .tag--closed {
	background: rgba(189, 72, 101, 0.1);
	color: #bd4865;
}

.dash-tbl-col p {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #131521;
	font-family: "Circular Std Book";
}

.Dashboard .AuctionPage {
	display: inherit;
}

@media only screen and (max-width: 1330px) {
	.issuances-table .table {
		display: block;
	}
}

@media only screen and (max-width: 900px) {
	.issuances-page-topbar {
		padding-left: 30px;
	}
}

@media only screen and (max-width: 1024px) {
	.issuances-table .table {
		margin-bottom: 80px;
	}
}

@media only screen and (max-width: 600px) {
	.issuances-table .dashTitle h4 {
		font-size: 19px;
		line-height: 25px;
	}

	.issuances-table .table .property-col {
		min-width: 250px;
	}

	.issuances-table .table .status-col {
		min-width: 190px;
	}

	.issuances-table .table .amount-raising-col,
	.issuances-table .table .date-col {
		min-width: 140px;
	}

	.issuances-page-topbar h1 {
		font-size: 28px;
		line-height: 36px;
	}

	.issuances-page-topbar {
		padding-left: 15px;
	}

	.issuances-table .table .property-col h6 {
		font-size: 16px;
		line-height: 23px;
	}

	.issuances-table .table .property-col img {
		width: 40px;
		height: 40px;
		margin-right: 15px;
	}

	.issuances-table .table td {
		padding: 15px;
	}
	.issuances-table .table thead th {
		padding: 12px 15px;
	}

	.issuances-table .dashTitle-bar {
		padding: 30px 15px 10px 15px;
	}
}

@media only screen and (max-width: 480px) {
	.dashTitle-bar {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.issuances-table .createauction-btn {
		margin-top: 20px;
	}

	.issuances-table .createauction-btn,
	.issuances-table .createauction-btn button {
		width: 100%;
	}
}
