.requirement-wrapper {
	display: flex;
	margin-top: 44px;
}

.requirement-wrapper .requirement-card {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.07);
	box-sizing: border-box;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	padding: 30px 40px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.requirement .nav {
	width: 100% !important;
	max-width: 416px;
}

.rental-table,
.loan-equity-table {
	margin-top: 44px;
}

.requirement-wrapper .requirement-card:first-child {
	margin-right: 30px;
}

.requirement-wrapper .requirement-card .req-card-img {
	margin-left: 30px;
}

.requirement-wrapper .requirement-card .card-text .text {
	/* display: flex; */
	/* align-items: center; */
	margin-bottom: 4px;
}

.requirement-wrapper .requirement-card .card-text .text h6 {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #131521;
	text-align: left;
	margin: 0;
	font-family: "CircularStd";
	font-weight: bold;
	position: relative;
}

.requirement-wrapper .requirement-card .card-text .text h6::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
}

.requirement-wrapper .requirement-card .card-text .text-2 h6 {
	margin-top: 24px;
}

.requirement-wrapper .requirement-card .card-text .text p {
	font-family: "Circular Std Book";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #4c506b;
	text-align: left;
	margin-bottom: 0;
	margin-top: 8px;
}

.requirement-wrapper .requirement-card .card-text .text .underline-text {
	text-decoration: underline;
}

.requirement-wrapper .requirement-card .card-text .text .underline-text:hover {
	text-decoration: underline;
	cursor: pointer;
}

@media only screen and (max-width: 840px) {
	.requirement-wrapper {
		flex-direction: column;
	}

	.requirement-wrapper .requirement-card:first-child {
		margin-right: 0px;
		margin-bottom: 30px;
	}
}

@media only screen and (max-width: 640px) {
	.requirement-wrapper .requirement-card:first-child {
		margin-right: 0px;
		margin-bottom: 15px;
	}

	.requirement-wrapper .requirement-card {
		padding: 20px;
	}
	.requirement-wrapper .requirement-card .req-card-img {
		margin-left: 20px;
	}

	.requirement-wrapper .requirement-card .card-text .text h6,
	.requirement-wrapper .requirement-card .card-text .text p {
		font-size: 13px;
		line-height: 18px;
	}
}
