.add_property_tabs .nav-tabs{
    align-items: center;
    display: inline-flex;
    margin-bottom: 8px;
    overflow: hidden;
}
.add_property_tabs .nav-tabs{
    border:1px solid rgba(0, 0, 0, 0.21);
    border-radius:8px;
}
.add_property_tabs .nav-tabs li{
    margin-top: -1px;
}
.add_property_tabs .nav-tabs li a{
    padding:16px 12px;
    margin-right: 0;
    border-right:1px solid rgba(0, 0, 0, 0.21);
    color:#222;
    cursor: pointer;
    letter-spacing: 0.5px;
    text-transform: capitalize ;
    font-weight: 500;
    border-bottom: 0px;
}
.add_property_tabs .nav-tabs li a:hover{
    border-bottom: 0px;
}
.add_property_tabs .nav-tabs li a.active{
    border:0;
    padding:16px 12px;
    background-color: rgb(51 136 223);
    color:white;
}
.add_property_tabs .nav-tabs li:last-child a{
    border-right: 0;
}
