.article_wrapper {
  max-width: 1170px;
  margin: 0 auto;
  margin-top: 173px;
  padding: 0 30px;
}

.article_wrapper h1 {
  font-style: normal;
  font-weight: 500;
  font-family: "CircularStd";
  font-size: 20px;
  line-height: 28px;
  color: #5b607d;
  padding-bottom: 40px;
}

.article_cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  padding-bottom: 190px;
}

.article_cards .article_card {
  height: 146px;
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 40px;
  gap: 24px;
}

.article_cards a {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 24px;
  transition: all 0.3s ease-in-out;
}

.article_cards a:hover {
  background: #ffffff;
  border: 1px solid rgba(56, 100, 228, 0.4);
  box-shadow: 0px 2px 32px rgba(56, 100, 228, 0.1);
  border-radius: 24px;
}

.article_cards .article_card h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #131521;
  font-family: "Circular Std Book";
}

.article_footer {
  padding-bottom: 50px;
}

@media only screen and (max-width: 580px) {
  .articles_wrapper {
    padding: 0 15px;
    margin-top: 130px;
  }

  .article_cards {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .article_cards .article_card {
    padding: 20px 24px;
    gap: 18px;
  }
}
