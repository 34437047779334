.contact-page {
	background: #f6f7fd;
}

.contact-form {
	padding: 145px 30px 0 30px;
}

.contact-page .contact-form-wrapper form {
	background: #ffffff;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
	border-radius: 16px;
	max-width: 880px;
	padding: 80px 115px;
	margin: 0 auto;
}

.contact-form ul,
.contact-form li {
	padding: 0;
	margin: 0;
}

.contact-form ul.signup-inputs li input {
	height: 50px;
	border: 1px solid #dee0ec;
	box-sizing: border-box;
	border-radius: 8px;
}

.contact-form .input-label,
.email-r-title,
.inquiring-options legend,
.contact-form .textarea-msg label {
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #131521;
	font-family: "CircularStd";
	margin: 0;
	padding-bottom: 12px;
}

.contact-form .contact-form-text {
	text-align: center;
	margin-bottom: 32px;
}

.contact-form .contact-form-text h1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 38px;
	text-align: center;
	color: #131521;
	margin-bottom: 12px;
}

.contact-form .contact-form-text p {
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #5b607d;
	font-family: "Circular Std Book";
}

.contact-form .inquiring-radio-buttons {
	display: flex;
	justify-content: space-between;
	gap: 12px;
	flex-wrap: wrap;
}

.contact-form .inquiring-radio-buttons .choice {
	background: #f6f7fd;
	border-radius: 8px;
	padding: 15px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #5b607d;
	margin: 0;
	font-family: "Circular Std Book";
	display: flex;
	align-items: center;
	flex: auto;
}

.contact-form .inquiring-radio-buttons .choice input {
	background: #ffffff;
	border: 1px solid #dee0ec;
	box-sizing: border-box;
	border-radius: 8px;
	width: 20px;
	height: 20px;
	margin-right: 15px;
}

.inquiring-options {
	margin: 35px 0;
}

.contact-form .textarea-msg {
	display: flex;
	flex-direction: column;
}

.contact-form .error,
.contact-form .textarea-msg .error {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #bd4865;
	font-family: "Circular Std Book";
	margin-top: 8px;
}

.contact-form .textarea-msg textarea {
	border: 1px solid #dee0ec;
	box-sizing: border-box;
	border-radius: 8px;
	min-height: 142px;
	padding-top: 15px;
	padding-left: 25px;
}

.contact-form ul.signup-inputs li input::placeholder,
.contact-form .textarea-msg textarea::placeholder {
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 19px;
	color: #9fa2b8;
	font-family: "Circular Std Book";
}

.contact-form ul.signup-inputs li input:focus,
.contact-form .textarea-msg textarea:focus {
	border: 1px solid #8ea8f3;
	box-shadow: 0 0 0 3px rgb(56 99 228 / 15%);
	outline: none;
}

.contact-form .send-btn {
	text-decoration: none;
	display: block;
	font-size: 15px;
	font-family: "CircularStd";
	font-weight: bold;
	color: rgb(255, 255, 255);
	line-height: 1.2;
	outline: none;
	background: none;
	border: none;
	width: 190px;
	height: 50px;
	background: #3864e4;
	border-radius: 6px;
	margin-right: 22px;
	transition: all 0.4s ease-in-out;
	margin-top: 20px;
	margin-left: auto;
	margin-right: 0;
}

.contact-form .send-btn:hover {
	background: #204ccf;
	color: #fff;
	box-shadow: 0px 10px 30px 0px rgb(74 62 243 / 30%);
	cursor: pointer;
	outline: none;
}

.contact-us-boxes {
	display: flex;
	max-width: 880px;
	margin: 0 auto;
	margin-top: 32px;
	gap: 28px;
}

.contact-us-boxes a {
	background: #ffffff;
	border-radius: 8px;
	padding: 25px;
	width: 100%;
	transition: box-shadow 0.3s ease-in-out;
}

.contact-us-boxes a:hover {
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
}

.contact-us-boxes .contact-us-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contact-us-boxes .contact-us-box .text h6 {
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #131521;
	margin-top: 16px;
	margin-bottom: 8px;
	font-family: "CircularStd";
	font-weight: bold;
}

.contact-us-boxes .contact-us-box .text p {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	color: #5b607d;
	font-family: "Circular Std Book";
}

.contact-page .copyrights-text {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #9fa2b8;
	font-family: "Circular Std Book";
	padding-top: 48px;
	padding-bottom: 32px;
	text-align: center;
	margin: 0;
}

@media only screen and (max-width: 740px) {
	.contact-page .contact-form-wrapper form {
		padding: 50px 30px;
	}
}

@media only screen and (max-width: 580px) {
	.contact-page .contact-form-wrapper form {
		padding: 40px 24px;
	}

	.contact-form {
		padding: 125px 15px 0 15px;
	}
}

@media only screen and (max-width: 480px) {
	.contact-us-boxes {
		flex-direction: column;
	}

	.contact-form .send-btn {
		width: 100%;
	}
}
