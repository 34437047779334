.mt-card {
	margin-top: 175px;
}

.mt-card-2 {
	margin-top: 30px;
}

.mb-content {
	padding-bottom: 16px;
}

.mt-content {
	padding-top: 16px;
}

.loader {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.proposal-container {
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 30px;
}

.proposal-wrapper {
	/* background: #f6f8fa; */
	border-radius: 24px;
	/* padding: 40px; */
}

.proposal-wrapper-2 {
	padding: 0;
}

.proposal-card .page-title {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 37px;
	color: #131521;
	margin-bottom: 40px;
}

.proposal-wrapper .card-title {
	display: flex;
	align-items: center;
	margin: 0;
	/* padding-bottom: 30px; */
}

.proposal-wrapper .card-title h4 {
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 32px;
	color: #131521;
	font-family: "CircularStd";
	padding-left: 20px;
}

.proposal-wrapper .proposal-para {
	font-style: normal;
	font-weight: 450;
	font-size: 17px;
	line-height: 25px;
	color: #131521;
	font-family: "Circular Std Book";
}

.proposal-wrapper .proposal-list {
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 25px;
	color: #131521;
	font-family: "Circular Std Book";
	padding-left: 20px;
}

.proposal-wrapper .proposal-list li {
	margin-bottom: 16px;
}

.proposal-wrapper .proposal-list li:last-child {
	margin-bottom: 0px;
}

.proposal-wrapper ol.proposal-list {
	padding-left: 35px;
}

.proposal-wrapper .faq-list {
	border-bottom: 1px solid #e3e7ec;
	padding-bottom: 24px;
	padding-top: 24px;
	padding-left: 0px;
	padding-right: 0px;
	/* padding: 24px 40px; */
}

.proposal-wrapper .faq-list:last-child {
	border: none;
}

.proposal-wrapper .faq-list h5 {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
	color: #131521;
	font-family: "CircularStd";
	padding-bottom: 12px;
}

/* .proposal-wrapper-2 .card-title {
	padding: 40px 40px 10px 40px;
} */

.proposal-chart {
	margin-top: 80px;
}

/*--------- Accordion styling --------*/

.proposal-page-wrapper .accordion {
	border: 0;
	border-radius: 0;
}

.proposal-page-wrapper .accordion__heading {
	position: relative;
}

.proposal-page-wrapper .accordion__button:before {
	display: inline-block;
	content: "+";
	height: 38px;
	width: 38px;
	margin-right: 0;
	border-bottom: 0;
	border-right: 0;
	color: #31354e;
	font-family: "CircularStd";
	font-weight: 500;
	font-size: 20px;
	transform: rotate(0deg);
	position: absolute;
	right: 40px;
	background: #ffffff;
	border-radius: 8px;
	padding: 3px 13px;
}

.proposal-page-wrapper .accordion__button[aria-expanded="true"]::before,
.proposal-page-wrapper .accordion__button[aria-selected="true"]::before {
	transform: rotate(0deg);
	content: "\2212";
	background: #f5f5f9;
}

.proposal-page-wrapper.accordion__button:focus,
.proposal-page-wrapper .accordion__button:active {
	outline: none;
}

.proposal-page-wrapper .accordion__button {
	border-radius: 24px !important;
	border-bottom: 0 !important;
	margin-bottom: 0 !important;
	background-color: #f6f8fa !important;
	color: white !important;
	font-size: 13px;
	padding: 40px;
}

.proposal-page-wrapper .accordion__panel {
	padding: 0 40px 32px 40px !important;
	background: #ffffff;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
	border-radius: 0px 0px 24px 24px !important;
	border-top: 0;
	border-left: 1px solid rgba(0, 0, 0, 0.07);
	border-right: 1px solid rgba(0, 0, 0, 0.07);
	border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.proposal-page-wrapper .accordion__item+.accordion__item {
	border: 0;
}

.proposal-page-wrapper .accordion__button[aria-expanded="true"]::before .accordion__panel {
	background-color: red;
	background: red;
}

.proposal-page-wrapper .accordion__button[aria-expanded="true"] {
	background: #ffffff !important;
	border-top: 1px solid rgba(0, 0, 0, 0.07);
	border-left: 1px solid rgba(0, 0, 0, 0.07);
	border-right: 1px solid rgba(0, 0, 0, 0.07);
	border-bottom: 0;
	box-shadow: 0px -8px 7px rgb(0 0 0 / 3%);
	border-radius: 24px 24px 0px 0px !important;
	padding: 40px 40px 24px 40px !important;
}

/* .proposal-page-wrapper .accordion__button:hover[aria-expanded="true"] {
	cursor: inherit;
} */

.hash-link {
	color: #3864e4;
	/* text-decoration: underline !important; */
	/* margin-top: 20px; */
}

.hash-link:hover::after {
	width: 0 !important;
}

.hash-link:hover {
	border: none !important;
	color: #082888;
}

.blue-link {
	color: #0a58ca;
}

/*--------- Accordion styling Ends --------*/

@media only screen and (max-width: 580px) {
	.proposal-container {
		padding: 0 15px;
	}

	.proposal-page-wrapper .accordion__panel .canvasjs-chart-canvas {
		width: 100% !important;
	}

	.proposal-chart {
		margin-top: 50px;
	}

	.proposal-card .page-title {
		font-size: 26px;
		line-height: 34px;
	}

	.mt-card {
		margin-top: 110px;
	}

	.proposal-card .page-title {
		margin-bottom: 32px;
	}

	.proposal-wrapper-2 .card-title {
		padding: 0;
	}

	.proposal-page-wrapper .accordion__button[aria-expanded="true"] {
		padding: 32px 18px !important;
	}

	.proposal-page-wrapper .accordion__panel {
		padding: 0 18px 32px 18px !important;
	}

	.proposal-wrapper-2 {
		padding: 0;
	}

	.proposal-wrapper .card-title h4 {
		font-size: 20px;
		line-height: 28px;
		padding-left: 12px;
	}

	.accordion__button {
		padding: 32px 18px;
	}

	.proposal-page-wrapper .accordion__button:before {
		right: 18px;
	}

	.proposal-wrapper .faq-list {
		padding: 20px 18px;
	}
}