.article_sec {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 30px;
  margin-top: 140px;
}

.article_sec p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #131521;
  font-family: "Circular Std Book";
  margin-bottom: 15px;
}
