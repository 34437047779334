.get-started-section {
	padding: 0 30px;
}

.get-started {
	margin-top: 140px;
}

.get-started-wrapper {
	max-width: 1110px;
	margin: 0 auto;
	background: #f6f7fd;
	border-radius: 24px;
	padding: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 50px;
	position: relative;
	z-index: 1;
}

.get-started-wrapper::after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	background-image: url(../../Images/get-started-logo.svg);
	width: 386px;
	height: 278px;
	z-index: -1;
}

.get-started-wrapper .get-started-text {
	max-width: 485px;
}

.get-started-wrapper .get-started-text h3 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 38px;
	letter-spacing: 0.5px;
	color: #131521;
}

.get-started-wrapper .get-started-text p {
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: 0.4px;
	color: #4c506b;
	font-family: "Circular Std Book";
	margin-top: 20px;
}

.get-started-wrapper .get-started-btn button {
	text-decoration: none;
	display: block;
	font-size: 14px;
	font-family: "CircularStd";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: 0.3px;
	color: #ffffff;
	outline: none;
	background: none;
	border: none;
	width: 197px;
	height: 57px;
	background: #3864e4;
	border-radius: 10px;
	transition: all 0.4s ease-in-out;
}

.get-started-wrapper .get-started-btn button:hover {
	background: #123bb6;
	cursor: pointer;
}

@media only screen and (max-width: 840px) {
	.get-started-wrapper {
		padding: 70px 40px;
	}
}

@media only screen and (max-width: 700px) {
	.get-started-wrapper {
		padding: 50px 20px;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		gap: 30px;
	}
}

@media only screen and (max-width: 580px) {
	.get-started-section {
		padding: 0 15px;
	}

	.get-started {
		margin-top: 100px;
	}

	.get-started-btn,
	.get-started-wrapper .get-started-btn button {
		width: 100%;
	}
}
