@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700");

*,
*:after,
*:before {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

dl,
ol,
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
}

html {
	scroll-behavior: smooth;
}

html,
body {
	margin: 0;
	padding: 0;
	font-family: "Montserrat", "CircularStd", "Circular Std Book", "sans-serif";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #ffffff;
}

button:focus {
	border: 1px solid #ffb601;
}

.hero {
	background-repeat: no-repeat;
	width: 100%;
	background-size: cover;
	background-position: center center;
	position: relative;
	/* z-index: 11; */
	/* overflow: hidden;
	background: rgba(254, 249, 245, 0.85);
	backdrop-filter: blur(100px); */
}

a {
	text-decoration: none !important;
}

/* Note: backdrop-filter has minimal browser support */

.hero::after {
	content: "";
	position: absolute;
	background: rgba(254, 249, 245, 0.85);
	/* background-image: url(./Images/hero-bg.svg); */
	background-repeat: no-repeat;
	backdrop-filter: blur(100px);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: -2;
}

.hero::before {
	content: "";
	position: absolute;
	background: rgba(254, 249, 245, 0.85);
	backdrop-filter: blur(100px);
	width: 50%;
	height: 100%;
	top: 0;
	right: 0;
	z-index: -1;
}

.hero-group {
	max-width: 1110px;
	margin: 0 auto;
	position: relative;
	z-index: 99;
}

.hero-text h1 {
	font-size: 45px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.344;
	font-weight: 300;
	width: 100%;
}

.table > :not(:first-child),
.table > :not(caption) > * > * {
	border: none;
}

.hero-text .bold {
	font-weight: 600;
}

.hero-text .bold-color {
	color: #38f1bf;
	font-weight: 600;
}

.hero-text p {
	font-size: 23px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.35;
	font-weight: 300;
	padding: 20px 0;
}

.mortgage-sec {
	width: 100%;
	margin-top: 140px;
	position: relative;
	background-color: #fff;
	padding: 0 30px;
}

.mortgage-group {
	text-align: center;
}

.mortgage-text h3 {
	font-size: 35px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	font-weight: 600;
	line-height: 1.429;
	text-align: center;
	padding-bottom: 10px;
}

.gist-component {
	margin-bottom: 120px;
	padding-top: 140px;
}

.mortgage-text p {
	font-size: 30px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.5;
	text-align: center;
	font-weight: 300;
}

.mortgage-card-sec {
	max-width: 1260px;
	margin: 0 auto;
	padding: 0 30px;
	margin-top: 120px;
}

.mortgage-card-group {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 35px 1fr;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 40px;
	width: 100%;
}

.mortgage-card-group a,
.featured-card-group a {
	text-decoration: none;
}

.featured-card-sec {
	padding-top: 200px;
	overflow: hidden;
	padding: 0 15px;
}

.section-title {
	max-width: 1110px;
	margin: 0 auto;
	text-align: center;
}

.section-title h5 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 37px;
	color: #131521;
	margin-bottom: 28px;
}

.mortgage-card-title h5 {
	margin-top: 0px;
	margin-bottom: 60px;
}

.featured-card-group {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 26px 1fr 26px 1fr;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 26px;
	max-width: 1170px;
	margin: 0 auto;
}

.whatfor {
	margin-top: 100px;
}

.benefits-card-sec,
.stories-sec {
	margin-top: 200px;
}

.requirements-card-sec,
.benefits-card-sec {
	padding: 0 30px;
}

.howitworks-card-sec {
	margin-top: 140px;
	padding: 0 30px;
}

.benefit-card-group,
.featued-card-group,
.requirements-card-group,
.stories-group {
	margin-top: 60px;
}

.footer-section {
	/* background-color: rgb(48, 57, 78); */
	width: 100%;
	padding: 88px 0;
}

.fyLrfx {
	display: none !important;
}

.bg-ffffff {
	background-color: #ffffff;
}

@media only screen and (max-width: 991px) {
	.mortgage-card-group {
		grid-template-columns: repeat(1, 1fr);
	}

	.featured-card-group {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media only screen and (max-width: 640px) {
	.featured-card-group {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 30px;
	}

	.benefits-card-sec {
		margin-top: 140px;
	}

	.featured-card-sec {
		padding-top: 120px;
	}

	.benefit-card-group,
	.featued-card-group,
	.requirements-card-group,
	.stories-group {
		margin-top: 35px;
	}

	.benefits-card-sec,
	.stories-sec {
		margin-top: 100px;
	}

	.requirements-card-sec,
	.benefits-card-sec {
		padding: 0 15px;
	}

	.footer-section {
		padding-bottom: 80px;
	}

	.hero {
		background-position: 80%;
	}

	.featured-card-sec {
		padding: 0px;
	}
}

/*============================*/
/*ROTATING WORDS*/
/*============================*/

.cd-words-wrapper b {
	font-size: 45px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.444;
	font-weight: 300;
}

.cd-words-wrapper {
	display: inline-block;
	position: relative;
	/* float: left !important; */
	text-align: left;
}

.cd-words-wrapper b {
	display: inline-block;
	position: absolute;
	white-space: nowrap;
	left: 0;
	top: 0;
}

.hero-title .cd-words-wrapper {
	margin-right: 16px;
}

.cd-words-wrapper b.is-visible {
	position: relative;
}

.no-js .cd-words-wrapper b {
	opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
	opacity: 1;
}

.cd-headline.slide .cd-words-wrapper {
	overflow: hidden;
	vertical-align: top;
}

.cd-words-wrapper-2 b {
	font-size: 23px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.304;
	font-weight: 300;
	margin-top: 27px;
	display: block;
	padding-bottom: 6px;
}

.cd-words-wrapper-2 {
	display: block;
	position: relative;
	float: left !important;
	text-align: left;
	display: block;
}

.cd-words-wrapper-2 b {
	display: inline-block;
	position: absolute;
	left: 0;
	top: 0;
	display: block;
}

.cd-words-wrapper-2 b.is-visible {
	position: relative;
}

.no-js .cd-words-wrapper-2 b {
	opacity: 0;
}

.no-js .cd-words-wrapper-2 b.is-visible {
	opacity: 1;
}

.cd-headline.slide .cd-words-wrapper-2 {
	overflow: hidden;
	vertical-align: top;
}

.cd-headline.slide b {
	opacity: 0;
	top: 0.2em;
}

.Sold-btn {
	position: relative;
	z-index: 10;
	margin-top: 100px;
}

.Sold-btn::after {
	content: "";
	position: absolute;
	background-image: url("../src/Images/diamond-shape.svg");
	background-repeat: no-repeat;
	width: 152px;
	height: 155px;
	bottom: -100px;
	left: -40px;
	z-index: -1;
}

.Sold-btn .blue-btn {
	text-decoration: none;
	display: block;
	font-size: 25px;
	font-family: "CircularStd";
	font-weight: bold;
	color: #fff;
	line-height: 32px;
	outline: none;
	background: none;
	border: none;
	width: 350px;
	height: 80px;
	margin: 0 auto;
	background: #3864e4;
	border-radius: 70px;
	transition: all 0.4s ease-in-out;
}

.Sold-btn .blue-btn:hover {
	background: #1e4acf;
}

/* -------------------------------- 

xrotate-1 

-------------------------------- */
.cd-headline.rotate-1 .cd-words-wrapper {
	-webkit-perspective: 300px;
	-moz-perspective: 300px;
	perspective: 300px;
}

.cd-headline.rotate-1 b {
	opacity: 0;
	-webkit-transform-origin: 50% 100%;
	-moz-transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-o-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	-webkit-transform: rotateX(180deg);
	-moz-transform: rotateX(180deg);
	-ms-transform: rotateX(180deg);
	-o-transform: rotateX(180deg);
	transform: rotateX(180deg);
}

.cd-headline.rotate-1 b.is-visible {
	opacity: 1;
	-webkit-transform: rotateX(0deg);
	-moz-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	-o-transform: rotateX(0deg);
	transform: rotateX(0deg);
	-webkit-animation: cd-rotate-1-in 1.2s;
	-moz-animation: cd-rotate-1-in 1.2s;
	animation: cd-rotate-1-in 1.2s;
}

.cd-headline.rotate-1 b.is-hidden {
	-webkit-transform: rotateX(180deg);
	-moz-transform: rotateX(180deg);
	-ms-transform: rotateX(180deg);
	-o-transform: rotateX(180deg);
	transform: rotateX(180deg);
	-webkit-animation: cd-rotate-1-out 1.2s;
	-moz-animation: cd-rotate-1-out 1.2s;
	animation: cd-rotate-1-out 1.2s;
}

@-webkit-keyframes cd-rotate-1-in {
	0% {
		-webkit-transform: rotateX(180deg);
		opacity: 0;
	}

	35% {
		-webkit-transform: rotateX(120deg);
		opacity: 0;
	}

	65% {
		opacity: 0;
	}

	100% {
		-webkit-transform: rotateX(360deg);
		opacity: 1;
	}
}

@-moz-keyframes cd-rotate-1-in {
	0% {
		-moz-transform: rotateX(180deg);
		opacity: 0;
	}

	35% {
		-moz-transform: rotateX(120deg);
		opacity: 0;
	}

	65% {
		opacity: 0;
	}

	100% {
		-moz-transform: rotateX(360deg);
		opacity: 1;
	}
}

@keyframes cd-rotate-1-in {
	0% {
		-webkit-transform: rotateX(180deg);
		-moz-transform: rotateX(180deg);
		-ms-transform: rotateX(180deg);
		-o-transform: rotateX(180deg);
		transform: rotateX(180deg);
		opacity: 0;
	}

	35% {
		-webkit-transform: rotateX(120deg);
		-moz-transform: rotateX(120deg);
		-ms-transform: rotateX(120deg);
		-o-transform: rotateX(120deg);
		transform: rotateX(120deg);
		opacity: 0;
	}

	65% {
		opacity: 0;
	}

	100% {
		-webkit-transform: rotateX(360deg);
		-moz-transform: rotateX(360deg);
		-ms-transform: rotateX(360deg);
		-o-transform: rotateX(360deg);
		transform: rotateX(360deg);
		opacity: 1;
	}
}

@-webkit-keyframes cd-rotate-1-out {
	0% {
		-webkit-transform: rotateX(0deg);
		opacity: 1;
	}

	35% {
		-webkit-transform: rotateX(-40deg);
		opacity: 1;
	}

	65% {
		opacity: 0;
	}

	100% {
		-webkit-transform: rotateX(180deg);
		opacity: 0;
	}
}

@-moz-keyframes cd-rotate-1-out {
	0% {
		-moz-transform: rotateX(0deg);
		opacity: 1;
	}

	35% {
		-moz-transform: rotateX(-40deg);
		opacity: 1;
	}

	65% {
		opacity: 0;
	}

	100% {
		-moz-transform: rotateX(180deg);
		opacity: 0;
	}
}

@keyframes cd-rotate-1-out {
	0% {
		-webkit-transform: rotateX(0deg);
		-moz-transform: rotateX(0deg);
		-ms-transform: rotateX(0deg);
		-o-transform: rotateX(0deg);
		transform: rotateX(0deg);
		opacity: 1;
	}

	35% {
		-webkit-transform: rotateX(-40deg);
		-moz-transform: rotateX(-40deg);
		-ms-transform: rotateX(-40deg);
		-o-transform: rotateX(-40deg);
		transform: rotateX(-40deg);
		opacity: 1;
	}

	65% {
		opacity: 0;
	}

	100% {
		-webkit-transform: rotateX(180deg);
		-moz-transform: rotateX(180deg);
		-ms-transform: rotateX(180deg);
		-o-transform: rotateX(180deg);
		transform: rotateX(180deg);
		opacity: 0;
	}
}

.hero-group .hero-title {
	font-size: 45px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.444;
	font-weight: 300;
	width: 100%;
}

.hero-title h1 {
	font-weight: 600;
}

.light-text {
	font-weight: 300;
	font-size: 42px;
}

a:not([href]):not([tabindex]) {
	color: #cba3e6;
	text-decoration: none;
}

/* -------------------------------- 

xslide 

-------------------------------- */
.cd-headline.slide .cd-words-wrapper {
	overflow: hidden;
	vertical-align: top;
}

.cd-headline.slide b {
	opacity: 0;
	top: 0.2em;
}

.cd-headline.slide b.is-visible {
	top: 0;
	opacity: 1;
	-webkit-animation: slide-in 0.6s;
	-moz-animation: slide-in 0.6s;
	animation: slide-in 0.6s;
}

.cd-headline.slide b.is-hidden {
	-webkit-animation: slide-out 0.6s;
	-moz-animation: slide-out 0.6s;
	animation: slide-out 0.6s;
}

@-webkit-keyframes slide-in {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
	}

	60% {
		opacity: 1;
		-webkit-transform: translateY(20%);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes slide-in {
	0% {
		opacity: 0;
		-moz-transform: translateY(-100%);
	}

	60% {
		opacity: 1;
		-moz-transform: translateY(20%);
	}

	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@keyframes slide-in {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		-moz-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		-o-transform: translateY(-100%);
		transform: translateY(-100%);
	}

	60% {
		opacity: 1;
		-webkit-transform: translateY(20%);
		-moz-transform: translateY(20%);
		-ms-transform: translateY(20%);
		-o-transform: translateY(20%);
		transform: translateY(20%);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
	}
}

@-webkit-keyframes slide-out {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}

	60% {
		opacity: 0;
		-webkit-transform: translateY(120%);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(100%);
	}
}

@-moz-keyframes slide-out {
	0% {
		opacity: 1;
		-moz-transform: translateY(0);
	}

	60% {
		opacity: 0;
		-moz-transform: translateY(120%);
	}

	100% {
		opacity: 0;
		-moz-transform: translateY(100%);
	}
}

@keyframes slide-out {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
	}

	60% {
		opacity: 0;
		-webkit-transform: translateY(120%);
		-moz-transform: translateY(120%);
		-ms-transform: translateY(120%);
		-o-transform: translateY(120%);
		transform: translateY(120%);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(100%);
		-moz-transform: translateY(100%);
		-ms-transform: translateY(100%);
		-o-transform: translateY(100%);
		transform: translateY(100%);
	}
}

/* -------------------------------- 

xloading-bar 

-------------------------------- */

.cd-headline.loading-bar .cd-words-wrapper {
	overflow: hidden;
	vertical-align: top;
}

.cd-headline.loading-bar .cd-words-wrapper::after {
	/* loading bar */
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	height: 3px;
	width: 0;
	background: #38f1bf;
	z-index: 2;
	-webkit-transition: width 0.3s -0.1s;
	-moz-transition: width 0.3s -0.1s;
	transition: width 0.3s -0.1s;
}

.cd-headline.loading-bar .cd-words-wrapper.is-loading::after {
	width: 100%;
	-webkit-transition: width 5s;
	-moz-transition: width 5s;
	transition: width 5s;
}

.cd-headline.loading-bar b {
	/* top: 0.1em; */
	opacity: 0;
	-webkit-transition: opacity 0.3s;
	-moz-transition: opacity 0.3s;
	transition: opacity 0.3s;
}

.cd-headline.loading-bar b.is-visible {
	opacity: 1;
	top: 0;
}

@media only screen and (max-width: 640px) {
	.popup-btn button.closes,
	.popup-btn button.ask-question {
		width: 24px !important;
		margin-top: 10px !important;
	}
}

@media only screen and (max-width: 600px) {
	.cd-words-wrapper-2 b,
	.cd-words-wrapper-2 {
		width: 100%;
		display: inline-block;
		white-space: pre-wrap;
		/* font-size: 18px; */
	}
}

.d-modal-heading {
	font-size: 20px;
	font-weight: 700;
	line-height: 1.3;
	padding-bottom: 25px;
}

.d-modal-text {
	font-size: 14px;
	line-height: 1.857;
	font-family: Montserrat;
	color: #2e3238;
}

.clickable-link {
	cursor: pointer !important;
}

.newsletter-sec {
	margin-top: 100px;
	padding: 0 30px;
}

.modal {
	overflow: initial !important;
}

.hero-btn {
	text-decoration: none;
	display: block;
	font-size: 14px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	text-transform: uppercase;
	line-height: 1.2;
	outline: none;
	background: none;
	border: none;
	border-radius: 30px;
	padding: 10px 30px;
	background-image: linear-gradient(
		-60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	box-shadow: 0px 6px 25px 0px rgba(51, 136, 223, 0.2);
	transition: all 0.3s ease-in-out;
}

.hero-btn:hover {
	background: #fff;
	color: #202020;
	box-shadow: 0px 10px 30px 0px rgba(255, 255, 255, 0.3);
	cursor: pointer;
	outline: none;
}

.error {
	color: red;
}

.range-vertical .input-range__slider {
	border: 1px solid #5ad4ee !important;
}

.popup-btn button.closes {
	background: #fff;
	border: 1px solid #000;
	width: 24px;
	height: 24px;
	position: relative;
	color: #202020;
	font-weight: 800;
	border-radius: 50%;
	position: absolute;
	top: -11px;
	right: -10px;
}

.rent-table-sec,
.requirement-section {
	padding-top: 200px;
	padding-left: 30px;
	padding-right: 30px;
}

.requirement-section {
	padding-top: 0;
}

.loan-table-sec {
	padding-top: 200px;
	padding-left: 30px;
	padding-right: 30px;
}

.financial-model-sec {
	padding-top: 140px;
}

@media only screen and (max-width: 580px) {
	.rent-table-sec,
	.requirement-section,
	.financial-model-sec {
		padding-top: 100px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.financial-model-sec {
		padding-left: 0px;
		padding-right: 0px;
	}

	.accordion__panel .canvasjs-chart-canvas {
		width: 100% !important;
	}

	.loan-table-sec {
		padding-top: 100px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.gist-component {
		margin-bottom: 60px;
		padding-top: 120px;
	}
}

/* slick styling */

.region_card_container .slick-slider{
	max-height: 280px !important;
}

.region_card_container .slick-list .slick-track{
	height: 300px !important;
}